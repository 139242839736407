<template>
  <div class="page tenant-manage">
    <gc-header header-data="tenantManage">
      <template #ops>
        <el-button
          type="primary"
          @click="addTenant"
          v-has="'utos:tenant:maintain'"
          >新增租户</el-button
        >
      </template>
    </gc-header>
    <div class="page-main">
      <div class="page-main-right">
        <gc-custom-search
          key-word="租户名称"
          placeholder="请输入检索关键字"
          :search.sync="tenantSearchName"
          @on-keyup-enter="queryTenantList"
        >
          <i
            class="el-icon-search"
            slot="icon"
            style="cursor: pointer"
            @click="queryTenantList"
          ></i>
        </gc-custom-search>
        <gc-empty v-if="tableData.length === 0" />
        <div class="table-area" v-loading.lock="showLoading" v-else>
          <gc-table
            class="table"
            :columns="columns"
            :table-data="tableData"
            :border="true"
            :show-page="true"
            :current-page="page.current"
            :total="page.total"
            @row-click="handleRowClick"
            @current-page-change="queryTenantList"
          ></gc-table>
        </div>
      </div>
    </div>
    <add-tenant ref="addTenantModel" @emit-refresh="queryTenantList(0)" />
  </div>
</template>

<script>
import AddTenant from "./components/AddTenant.vue";
import {
  apiGetTenantList,
  apiActivateTenantAdmin,
  apiResetTenantAdminPassword,
} from "@/api/tenant";
import dayjs from "dayjs";
import { encrypt } from "@/utils";

export default {
  name: "TenantManage",
  components: { AddTenant },
  props: {},
  data() {
    return {
      tenantSearchName: "", // 租户名称
      columns: [
        {
          key: "name",
          name: "租户名称",
          tooltip: true,
        },
        // {
        //   key: "company_name",
        //   name: "企业名称",
        //   tooltip: true,
        // },
        {
          key: "enable",
          name: "租户状态",
          tooltip: true,
          render: (h, { enable }) => {
            let text = enable ? "正常" : "禁用";
            return (
              <div
                style={{
                  display: "flex",
                  "justify-content": "flex-start",
                  "align-items": "center",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "6px",
                    height: "6px",
                    "border-radius": "50%",
                    "background-color": enable ? "#12B3C7" : "#EC6B60",
                    "margin-right": "6px",
                  }}
                ></span>
                {text}
              </div>
            );
          },
        },
        {
          key: "subdomain",
          name: "域名",
          tooltip: true,
        },
        // {
        //   key: "contact",
        //   name: "联系人",
        //   tooltip: true,
        // },
        // {
        //   key: "phone",
        //   name: "联系电话",
        //   tooltip: true,
        // },
        {
          key: "createTime",
          name: "创建时间",
          tooltip: true,
          render: (h, row) => {
            return h(
              "span",
              {},
              dayjs.utc(row.createTime).format("YYYY-MM-DD")
            );
          },
        },
        {
          key: "operate",
          name: "操作",
          render: (h, row) => {
            const { hasTenantAdmin } = row;
            return h(
              "span",
              {
                class: "grant",
                on: {
                  click: (e) => {
                    this.handleActivate(row);
                    e.stopPropagation();
                  },
                },
              },
              hasTenantAdmin ? "重置密码" : "激活管理员账号"
            );
          },
        },
      ],
      tableData: [],
      page: {
        size: 10,
        total: 100,
        current: 5,
      },
      showLoading: false,
    };
  },
  computed: {},
  activated() {
    this.queryTenantList(1);
  },
  methods: {
    handleActivate(row) {
      const { hasTenantAdmin, id, subdomain } = row;
      let info = "确定要激活该租户管理员账号吗？",
        api = apiActivateTenantAdmin,
        successMsg = "激活成功",
        params = {
          tenantId: id,
        };
      if (hasTenantAdmin) {
        info = "确定要重置租户管理员密码吗？";
        api = apiResetTenantAdminPassword;
        successMsg = "重置密码成功";
        params["loginAccount"] = subdomain + "admin";
        params["newPwd"] = encrypt("jk300349");
      }
      this.$confirm(info).then(() => {
        api(params).then(() => {
          this.$message.success(successMsg);
          this.queryTenantList(1);
        });
      });
    },
    queryTenantList(page) {
      //
      this.showLoading = true;
      apiGetTenantList({
        name: this.tenantSearchName,
        page: page - 1 || 0,
        size: 10,
      })
        .then(({ dataList, current, total }) => {
          this.tableData = dataList;
          if (dataList.length === 0) this.$message.info("查询结果为空");
          this.page = {
            size: 10,
            total,
            current: current + 1,
          };
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    addTenant() {
      this.$refs.addTenantModel.show("add");
    },
    handleRowClick({ row }) {
      this.$router.push({
        path: "/tenant/detail",
        query: {
          tenantId: row.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-main {
  height: 100%;
}
.page-main-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: hidden;
  padding-bottom: 0;
  .table-area {
    margin-top: 22px;
    height: calc(100%- 62px);
    flex: 1;
  }
}
::v-deep {
  .gc-table {
    .grant {
      font-size: 14px;
      color: #4d6bff;
      line-height: 21px;
      cursor: pointer;
    }
  }
}
</style>
